import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-lab-employee',
  templateUrl: './lab-employee.component.html',
  styleUrls: ['./lab-employee.component.scss']
})
export class LabEmployeeComponent implements OnInit {

  getLabListArray;

  getSelectLab = "";
  getName = "";
  getTel = "";
  getNic = "";
  getExperience = "";

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.ViewList();
   }

  ngOnInit(): void {
  }

  ViewList(){


    this.webApiService.getLabData().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getLabListArray = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

  }

  Validation(){

    if(this.getSelectLab == "" || this.getName == "" || this.getNic == "" || this.getExperience == ""){
      this.toastr.error("Empty Field Found", "Error");
    }else{
      this.Save();
    }




  }

  Save(){

    const labData = {
      labID: this.getSelectLab,
      name: this.getName,
      contact: this.getTel,
      nic: this.getNic,
      experience: this.getExperience,
      addedBy: 'admin'
  };

    this.webApiService.registerEmployee(labData).subscribe((res) => {

        console.log(res);
        this.toastr.success("Insert Success", 'Success!');
        // let response = JSON.stringify(res);
        // let e_data = JSON.parse(response).Data;
        // let e_code = JSON.parse(response).Code;

        // if(e_code == "S100"){
        //   this.toastr.success(e_data, 'Info!');
        //   this.router.navigate(['/Login']);
        // }else if(e_code == "E100"){
        //   this.toastr.error(e_data, "Error!");
        // }else{
        //   this.toastr.error('Something went wrong Please try Again', 'Error!');
        // }

    }), (err) => {
      console.log(err);

        this.toastr.error('Something went wrong Please try Again', 'Error!');
    }







  }

}
