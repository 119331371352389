import { ChannelingAPIService } from 'src/app/ClassFile/channel/channeling-api.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-request-channeling',
  templateUrl: './request-channeling.component.html',
  styleUrls: ['./request-channeling.component.scss']
})
export class RequestChannelingComponent implements OnInit {

  getCustomerListArray;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(private toastr: ToastrService,private webApiService: ChannelingAPIService, private webApiService2: WebapiService,) {


  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }




  ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
    };
    this.ViewList();
  }

  ViewList(){


    this.webApiService.requestChanneling().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getCustomerListArray = res;

          this.dtTrigger.next();
          console.log(this.getCustomerListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

  }

  Approve(id){


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Active this Customer Account?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Active it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.approveAccount(id);
      }
    })



  }

  approveAccount(id){

      var data = {
        id : id,
      }

      console.log(data);


      this.webApiService.completeChannel(data).subscribe((res) => {
        //console.log(res);
        this.toastr.success("Successfully Activated", 'Success!');
        this.ViewList();

      }), (err) => {
        console.log(err);

          this.toastr.error('Something went wrong Please try Again', 'Error!');
      }

  }

  deleteAccount(id){

      var data = {
        id : id,
      }

      this.webApiService.rejectChannel(data).subscribe((res) => {
        console.log(res);
        this.toastr.success("Successfully Rejected", 'Success!');
        this.ViewList();

      }), (err) => {
        console.log(err);

          this.toastr.error('Something went wrong Please try Again', 'Error!');
      }

  }

  delete(rowID){


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Reject this Request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAccount(rowID);
      }
    })



  }


}
