import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  getSelectType = "";
  getSelectUser = "";
  getName = "";
  getPassword = "";

  getUserListArray;

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {

  }


  ngOnInit(): void {
  }



  Validation(){

    if(this.getName == "" || this.getPassword == ""){
      this.toastr.error('Please Fill Empty Fields', 'Error!');
    }else{
        this.saveData();
    }

  }

  saveData(){

    const labData = {
      contactNo: this.getName,
      type: this.getSelectType,
      _id: this.getSelectUser,
      password: this.getPassword,
  };

  console.log(labData);


    this.webApiService.createAccount(labData).subscribe((res => {

        console.log(res);
        this.toastr.success("Insert Success", 'Success!');
    }), (err) => {
      console.log(err);
        this.toastr.error('Something went wrong Please try Again', 'Error!');
    })




  }


  getLabList(){

      this.webApiService.getLabData().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getUserListArray = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })



  }

  getDriverList(){


    this.webApiService.getDriverList().subscribe((res => {

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.getUserListArray = res;
        //console.log(this.getLabListArray);
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })


  }

  getUsers(){

    if(this.getSelectType != ''){
      if(this.getSelectType == 'Lab'){
        this.getLabList();
      }else{
        this.getDriverList();
      }
    }


  }

}
