import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.component.html',
  styleUrls: ['./auth-signin.component.scss']
})
export class AuthSigninComponent implements OnInit {

  getEmail: String = "";
  getPassword: String = "";

  constructor(private toastr: ToastrService,private router: Router) { }

  ngOnInit() {
  }



  checkPassword(){
    //console.log("run");
   // console.log(this.getEmail);

    if(this.getEmail.trim() === "admin" && this.getPassword.trim() === "789"){
      sessionStorage.setItem('isLogin' , "true");
      this.router.navigate(['/Home']);
    }else{
      this.toastr.error('Invalid Email or Password!', 'Error!');
    }



  }

}
