<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fill Details">
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Select Lab</label>
              <div class="col-sm-9">
                <select
                    disabled
                    class="custom-select"
                    required
                    name="getSelectLab"
                    [(ngModel)]="getSelectLab">
                  <option value="">Select</option>
                  <option *ngFor="let val of getLabListArray" value="{{ val._id }}">{{ val.name }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Testing Name</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getTestingName"
                  [(ngModel)]="getTestingName">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Description</label>
              <div class="col-sm-9">
                <textarea
                  name="getDescription"
                  [(ngModel)]="getDescription"
                  class="form-control"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Lab Price</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getLabPrice"
                  [(ngModel)]="getLabPrice">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Our Price</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getPrice"
                  [(ngModel)]="getPrice">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Custom Availability</label>
              <div class="col-sm-9">
                <select
                  name="getAvailability"
                  [(ngModel)]="getAvailability"
                  class="custom-select"
                  required>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-success col-md-12" (click)="Validation()">Update</button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
