<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
      <perfect-scrollbar [style.max-height]="'500px'">
        <div class="table-responsive ng-tns-c94-73">
        <table class="table table-hover m-b-0">
          <thead>
          <tr>
            <th><span>Service Type</span></th>
            <th><span>Job No</span></th>
            <th><span>Full Name</span></th>
            <th><span>Booking Date</span></th>
            <th><span>Tel</span></th>
            <th><span>Total</span></th>
            <th><span>Driver Status</span></th>
            <th><span>Lab Status</span></th>

          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getJobListArray">
              <td>
                <h5 *ngIf="val.serviceType == 'Home Visit' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.serviceType }}</span></h5>
                <h5 *ngIf="val.serviceType == 'Lab Visit' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-secondary ng-tns-c85-32">{{ val.serviceType }}</span></h5>
              </td>
              <td>{{ val.jobID }}</td>
              <td>{{ val.userName }}</td>
              <td>{{ val.bookingDate }}</td>
              <td>{{ val.tel }}</td>
              <td>{{ val.totalAmount }}</td>
              <td>
                <h5 *ngIf="val.driverStatus == 'Pending' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-warning ng-tns-c85-32">{{ val.driverStatus }}</span></h5>
                <h5 *ngIf="val.driverStatus == 'On the way' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-info ng-tns-c85-32">{{ val.driverStatus }}</span></h5>
                <h5 *ngIf="val.driverStatus == 'Completed' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.driverStatus }}</span></h5>
              </td>
              <td>
                <h5 *ngIf="val.status == 'Pending' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-warning ng-tns-c85-32">{{ val.status }}</span></h5>
                <h5 *ngIf="val.status != 'Pending' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-info ng-tns-c85-32">{{ val.status }}</span></h5>
              </td>


            </tr>

          </tbody>
        </table>
      </div>
      </perfect-scrollbar>
    </app-card>
  </div>
</div>
