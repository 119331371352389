import { ChannelingAPIService } from 'src/app/ClassFile/channel/channeling-api.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { WebapiService } from "src/app/ClassFile/webapi.service";

@Component({
  selector: "app-edit-doctor",
  templateUrl: "./edit-doctor.component.html",
  styleUrls: ["./edit-doctor.component.scss"],
})
export class EditDoctorComponent implements OnInit {

  getID = "";

  getName = "";
  getContactNumber = "";
  getDescription = "";
  getExperience = "";
  getNIC = "";
  getQualification = "";
  getLogo = "";
  getTime = "";
  getPrice = "";
  getDiscount = "";

  oldImg = '';

  getSelectChanneling = "";
  getSelectSpecialization = "";

  getChannelingList: any = [];
  getSpecializationList: any = [];

  getFileType = "";

  ImgSelectedFile: File = null;

  constructor(
    private toastr: ToastrService,
    private webApiService: WebapiService,
    private apiService: ChannelingAPIService,
    private route: ActivatedRoute
  ) {
    this.getID = this.route.snapshot.params.id;
    this.viewChanneling();
    this.viewSpecialization();
    this.getDoctor();
  }

  ngOnInit(): void {}

  getDoctor(){

    let userData = {
      id: this.getID,
    }

    this.apiService.viewDoctorByID(userData).subscribe((res => {
      //console.log(res);

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        let body = JSON.parse(JSON.stringify(res));
        console.log(body);

        this.getName = body.name;
        this.getContactNumber = body.contact;
        this.getDescription = body.description;
        this.getExperience = body.experience;
        this.getNIC = body.nic;
        this.getQualification = body.qualification;
        this.oldImg = body.picture;
        this.getTime = body.time;
        this.getPrice =body.price;
        this.getDiscount = body.ourPrice;

        this.getSelectChanneling = body.channeling;
        this.getSelectSpecialization = body.specialization;
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })




  }



  Validation() {
    if (this.getName == "" || this.getContactNumber == "") {
      this.toastr.error("Please Fill Empty Fields", "Error!");
    } else {
      // if(this.getFileType == "image/png" || this.getFileType == "image/jpeg" || this.getFileType == "image/jpg"){
      this.Save();
      // }else{
      //   this.toastr.error('Selected File Not Valid. please select Image.', 'Error!');
      // }
    }
  }

  Save() {
    const labData = {
      id: this.getID,
      channeling: this.getSelectChanneling,
      specialization: this.getSelectSpecialization,
      name: this.getName,
      contact: this.getContactNumber,
      description: this.getDescription,
      experience: this.getExperience,
      nic: this.getNIC,
      qualification: this.getQualification,
      image: this.getLogo,
      oldImg: this.oldImg,
      addedBy: "admin",
      time: this.getTime,
      price: this.getPrice,
      ourPrice: this.getDiscount,
    };

    this.webApiService.editDoctor(labData).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success("Update Success", "Success!");
      },
      (err) => {
        console.log(err);

        this.toastr.error("Something went wrong Please try Again", "Error!");
      }
    );
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.getLogo = btoa(binaryString);
  }

  getBase64(event) {
    this.ImgSelectedFile = event.target.files;
    this.getFileType = this.ImgSelectedFile[0].type;
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(this.ImgSelectedFile[0]);
  }

  viewChanneling() {
    this.webApiService.getChannel().subscribe(
      (res) => {
        if (res == "") {
          this.toastr.error("Empty Data", "NO Data Found");
        } else {
          this.getChannelingList = res;
          //console.log(this.getLabListArray);
        }
      },
      (err) => {
        this.toastr.error(err.error.Error, "Error!");
      }
    );
  }

  viewSpecialization() {
    this.webApiService.getSpecialization().subscribe(
      (res) => {
        if (res == "") {
          this.toastr.error("Empty Data", "NO Data Found");
        } else {
          this.getSpecializationList = res;
          //console.log(this.getLabListArray);
        }
      },
      (err) => {
        this.toastr.error(err.error.Error, "Error!");
      }
    );
  }
}
