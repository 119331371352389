<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fill Details">
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Customer Name<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getName"
                  [(ngModel)]="getName"> 
              </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">City<span>*</span></label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    name="getCity"
                    [(ngModel)]="getCity">
                </div>
              </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Contact Number<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getContactNumber"
                  [(ngModel)]="getContactNumber">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Password<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getPassword"
                  [(ngModel)]="getPassword">
              </div>
            </div>
            <!-- <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Active Month<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getMonth"
                  [(ngModel)]="getMonth">
              </div>
            </div> --> 
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Membership Card Number<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getCardNo"
                  [(ngModel)]="getCardNo">
              </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Referral Number<span>*</span></label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    name="getRefNo" 
                    [(ngModel)]="getRefNo">
                </div>
              </div>
          </form>
        </div> 
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-6">
              <button type="button" class="btn btn-danger col-md-12" (click)="clear()">Clear</button>
            </div>
            <div class="col-sm-6">
                <button type="button" class="btn btn-success col-md-12" (click)="Validation()">Save</button>
              </div>
          </div>

        </div>
      </div>
    </app-card>
  </div>
</div> 
