import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  getSelectLab  = "";
  getPackageName = "";
  getSelecctTesting = "";
  getDescription = "";
  getPrice = "";
  getTestName  = "";
  getNote = "";
  getLabPrice = "";

  getTestingList : any[] = [];


  getLabListArray;
  getTestingListArray;

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.ViewList();
   }

  ngOnInit(): void {
  }

  ViewList(){


    this.webApiService.getLabData().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getLabListArray = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

  }

  getLab(){

    let userData = {
      id: this.getSelectLab,
    }

    this.webApiService.getTestingDetailsByLabID(userData).subscribe((res => {

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.getTestingListArray = res;
        //console.log(this.getLabListArray);
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })

  }


  getTesting(){

    let userData = {
      id: this.getSelecctTesting,
    }

    this.webApiService.getTestingDetailsByID(userData).subscribe((res => {
      console.log(res);

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        let body = JSON.parse(JSON.stringify(res));
        console.log(body.name);
        this.getDescription = body.description;
        this.getTestName = body.name;

        //this.getTestingListArray = res;
        //console.log(this.getLabListArray);
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })




  }



  deleteRow(id){
    this.toastr.success('Success', 'Deleted!');
    this.getTestingList.splice(id,1);
  }

  AddToTable(){

    if(this.getSelecctTesting == ""){
      this.toastr.error('Please Fill Empty Fields', 'Error!');
    }else{

      let data = {
        testID : this.getSelecctTesting,
        testName : this.getTestName,
        description : this.getDescription
      }

      this.getTestingList.push(data);
      this.getSelecctTesting = "";
      this.getDescription = "";

    }


  }

  Valication(){

    let data = {
      labID: this.getSelectLab,
      name: this.getPackageName,
      testingList: this.getTestingList,
      price: this.getPrice,
      note: this.getNote,
      labPrice: this.getLabPrice,
      addedBy: 'Admin'
    }

    if(this.getSelectLab == "" || this.getPrice == "" || this.getPackageName == ""){
      this.toastr.error('Please Select Details or Fill All Details', 'Error!');
    }else{

      this.webApiService.createPackage(data).subscribe((res => {

        let response = JSON.stringify(res);
        let e_data = JSON.parse(response).data;
        //let e_code = JSON.parse(response).code;
        //cosole.log(e_data);

          this.clearAll();
          this.toastr.success(e_data, "Success!");

        }), (err) => {
            this.toastr.error('Something went wrong Please try Again', 'Error!');
        })

      }

  }

  clearAll(){
    this.getSelectLab  = "";
    this.getPackageName = "";
    this.getSelecctTesting = "";
    this.getDescription = "";
    this.getPrice = "";
    this.getTestName  = "";
    this.getLabListArray;
    this.getTestingListArray;
    this.getTestingList = [];
    this.getNote = "";

  }



}
