import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-reset-pw',
  templateUrl: './reset-pw.component.html',
  styleUrls: ['./reset-pw.component.scss']
})
export class ResetPwComponent implements OnInit {

  getNumber = "";
  getPassword = "123456";

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {

  }

  ngOnInit(): void {
  }

  Validation(){

    if(this.getNumber == "" || this.getPassword == ""){
      this.toastr.error('Please Fill Empty Fields', 'Error!');
    }else{
        this.saveData();
    }

  }
 
  saveData(){

    const labData = {
      userName: this.getNumber,
      password: this.getPassword,
    };

    this.webApiService.passwordRest(labData).subscribe((res => {


        console.log(res);
        this.toastr.success(res.toString(), 'Success!');
        this.getNumber = "";
    }), (err) => {
      console.log(err);

      if (err.status === 401) {
        console.log("Unauthorized access - 401");
        const errorMessage = err.error?.Error || 'Unauthorized access. Please check your credentials.';
        this.toastr.error(errorMessage, 'Error!');
        } else if (err.status === 500) {
            console.log("Internal Server Error - 500");
            this.toastr.error('Server error. Please try again later.', 'Error 500!');
        } else {
            // Default error handler
            console.log(err);
            this.toastr.error('Something went wrong. Please try again.', 'Error!');
        }



       // this.toastr.error('Something went wrong Please try Again', 'Error!');
    })




  }


}
