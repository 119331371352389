<div class="auth-wrapper">
  <div class="auth-content">
    <div class="card">
      <div class="row align-items-center text-center">
        <div class="col-md-12">
          <div class="card-body">
            <form action="javascript:">
            <img src="assets/images/logo.png" alt="" class="img-fluid mb-4">
            <h4 class="mb-3 f-w-400">Signin</h4>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="feather icon-mail"></i></span>
              </div>
              <input type="email" class="form-control" placeholder="Email address" name="getEmail" [(ngModel)]="getEmail">
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="feather icon-lock"></i></span>
              </div>
              <input type="password" class="form-control" placeholder="Password" name="getPassword" [(ngModel)]="getPassword" >
            </div>
            <button class="btn btn-block btn-primary mb-4" (click)="checkPassword()">Signin</button>
            <!-- <p class="mb-2 text-muted">Forgot password? <a [routerLink]="['/auth/reset-password']" class="f-w-400">Reset</a></p> -->
            <!-- <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup']" class="f-w-400">Signup</a></p> -->
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
