import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';
declare var $: any;
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-channling-list',
  templateUrl: './channling-list.component.html',
  styleUrls: ['./channling-list.component.scss']
})
export class ChannlingListComponent implements OnInit {

  getBaseURL = environment.imgUrl;

  getLabListArray;



  constructor(
    private toastr: ToastrService,
    private webApiService: WebapiService,) {
    this.ViewList();
   }


  ngOnInit(): void {
  }

  ViewList(){


    this.webApiService.getChannel().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getLabListArray = res;
          console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }

  deleteLab(id){


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Delete this Lab?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {


        var data = {
          "id" : id
        }

        this.webApiService.deleteLab(data).subscribe((res => {

          console.log(res);
          this.toastr.success("Success", res.toString());
          this.ViewList();

          // if(res == ""){
          //   this.toastr.error("Empty Data", "NO Data Found");
          // }else{
          //   this.getLabListArray = res;
          //   //console.log(this.getLabListArray);
          // }

      }), (err) => {
        console.log(err);
          this.toastr.error(err.error.Error, 'Error!');
      })


      }
    })

  }


}
