<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
                <h5>Customer Details</h5>
                <div class="form-group row">
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Register No</label>
                    <div class="col-sm-12">
                      <input
                      name="customerRegNo"
                      [(ngModel)]="customerRegNo"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Customer Name</label>
                    <div class="col-sm-12">
                      <input
                      name="customerName"
                      [(ngModel)]="customerName"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Contact No</label>
                    <div class="col-sm-12">
                      <input
                      name="customerTel"
                      [(ngModel)]="customerTel"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>






                </div>

                <div class="form-group row">
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Report Member Name</label>
                    <div class="col-sm-12">
                      <input
                      name="memberName"
                      [(ngModel)]="memberName"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Contact No</label>
                    <div class="col-sm-12">
                      <input
                      name="memberTel"
                      [(ngModel)]="memberTel"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Member Register No</label>
                    <div class="col-sm-12">
                      <input
                      name="memberRegNo"
                      [(ngModel)]="memberRegNo"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                </div>

                <h5>Job Details</h5>

                <div class="form-group row">

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Service Type</label>
                    <div class="col-sm-12">
                      <input
                      name="serviceType"
                      [(ngModel)]="serviceType"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Selected Lab</label>
                    <div class="col-sm-12">
                      <input
                      name="selectedJob"
                      [(ngModel)]="selectedJob"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>



                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Job Type</label>
                    <div class="col-sm-12">
                      <input
                      name="jobType"
                      [(ngModel)]="jobType"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Booking Date</label>
                    <div class="col-sm-12">
                      <input
                      disabled
                      type="text"
                      name="bookingDate"
                      [(ngModel)]="bookingDate"
                      class="form-control"
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Job Request Date/Time</label>
                    <div class="col-sm-12">
                      <input
                      disabled
                      name="jobDateTime"
                      [(ngModel)]="jobDateTime"
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Address</label>
                    <div class="col-sm-12">
                      <textarea
                      name="address"
                      [(ngModel)]="address"
                        disabled
                        class="form-control"></textarea>
                    </div>
                  </div>

                  <!-- <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Address on Map</label>
                    <div class="col-sm-12">
                      <textarea
                      name="mapLocation"
                      [(ngModel)]="mapLocation"
                        disabled
                        class="form-control"></textarea>
                    </div>
                  </div> -->

                  <div class="form-group col-md-4" style="display: none;">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Distance</label>
                    <div class="col-sm-12">
                      <input
                      name="distance"
                      [(ngModel)]="distance"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                </div>

                <h5>Requested Prescription Details</h5>

                <div class="form-group row">

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Note</label>
                    <div class="col-sm-12">
                      <textarea
                      name="note"
                      [(ngModel)]="note"
                        disabled
                        class="form-control"></textarea>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <img src="{{imageSrc}}" width="150px" />
                  </div>
 
                  <div class="form-group col-md-4">
                    <a (click)="goToLink()" class="btn btn-success">View Image</a>
                  </div>



                </div>


                <h5>Select Blood Test</h5>


                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-3 col-form-label">Select Testing</label>
                  <div class="col-sm-9">
                    <select
                        class="custom-select"
                        required
                        name="getSelecctTesting"
                        (change)="getTesting()"
                        [(ngModel)]="getSelecctTesting">
                      <option value="">Select</option>
                      <option *ngFor="let val of getTestingListArray" value="{{ val._id }}">{{ val.name }}</option>
                    </select>
                  </div>
                </div>


                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">ID</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control"
                        name="getTestName"
                        [(ngModel)]="getTestName">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">Unit Price</label>
                    <div class="col-sm-9">
                        <input
                        type="text"
                        class="form-control"
                        name="getDescription"
                        [(ngModel)]="getDescription">
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-12" style="text-align: right;">
                      <button type="reset" class="btn btn-danger col-md-6">Clear</button>
                      <button type="button" class="btn btn-info col-md-6" (click)="AddToTable()">Add</button>
                    </div>
                  </div>







                <div class="form-group row">

                  <div class="col-md-12">
                    <app-card cardTitle="Package Details">
                      <perfect-scrollbar [style.max-height]="'362px'">
                        <div class="table-responsive ng-tns-c94-73">
                          <table class="table table-hover m-b-0">
                            <thead>
                            <tr>
                              <th><span>TestingName</span></th>

                            </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let val of getTestingList; let i = index;">
                                <td>{{ val.testName }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </perfect-scrollbar>
                    </app-card>

                  </div>

                </div>







                <div class="form-group row">
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Sub Total</label>
                    <div class="col-sm-12">
                      <input
                      name="subTotal"
                      [(ngModel)]="subTotal"

                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Delivery Charges</label>
                    <div class="col-sm-12">
                      <input
                      name="delivery"
                      [(ngModel)]="delivery"
                      disabled
                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail3" class="col-sm-12 col-form-label">Total Amount</label>
                    <div class="col-sm-12">
                      <input
                      name="total"
                      [(ngModel)]="total"

                      type="text"
                      class="form-control"
                      >
                    </div>
                  </div>

                </div>

                <h5>Blood Collect Person</h5>

                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-3 col-form-label">Select Driver</label>
                  <div class="col-sm-9">
                    <select
                        name="diverID"
                        [(ngModel)]="diverID"
                        class="custom-select"
                        required
                      >
                      <option value="">Select</option>
                      <option *ngFor="let val of getDriverListArray" value="{{ val._id }}">{{ val.name }}</option>
                    </select>
                  </div>
                </div>

            <div class="form-group row">
              <div class="col-sm-12" style="text-align: right;">
                <button type="button" class="btn btn-danger col-md-6" (click)="confirmationBoxReject()">Reject</button>
                <button type="button" class="btn btn-info col-md-6" (click)="confirmationBox()">Create Job</button>
              </div>
            </div>


          </form>
        </div>



      </div>



    </app-card>
  </div>
</div>
