import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelingAPIService {

  getBaseURL = environment.baseUrl;

  readonly viewChannelURL = this.getBaseURL + "webApi/channeling/channelById";
  readonly updateChannelURL = this.getBaseURL + "webApi/channeling/updateChannel";
  readonly doctorByChannelURL = this.getBaseURL + "webApi/channeling/doctorsByChannelId";
  readonly changeDoctorAvailabilityURL = this.getBaseURL + "webApi/channeling/changeDoctorAvailability";

  readonly viewDoctorURL = this.getBaseURL + "webApi/channeling/getDoctorByID";

  readonly requestChannelingURL = this.getBaseURL + "webApi/channeling/getRequestChanneling";
  readonly rejectChannelRequestURL = this.getBaseURL + "webApi/channeling/rejectChannelRequest";
  readonly completeChannelRequestURL = this.getBaseURL + "webApi/channeling/completeChannelRequest";

  readonly updateCanneltimeStatusURL = this.getBaseURL + "webApi/channeling/updateChannelTimeStatus";
  readonly updateChannelingCenterAvailibilityStatusURL = this.getBaseURL + "webApi/channeling/updateChannelingCenterAvailibilityStatus";


  constructor(private http: HttpClient) { }

  updateChannelingCenterAvailibilityStatus(data){
    return this.http.post(this.updateChannelingCenterAvailibilityStatusURL, data);
  }

  updateChannelTimeStatus(data){
    return this.http.post(this.updateCanneltimeStatusURL, data);
  }
 
  completeChannel(data){
    return this.http.post(this.completeChannelRequestURL, data);
  }

  rejectChannel(data){
    return this.http.post(this.rejectChannelRequestURL, data);
  }

  requestChanneling(){
    return this.http.get(this.requestChannelingURL);
  }

  viewDoctorByID(data){
    return this.http.post(this.viewDoctorURL, data);
  }

  viewChannelByID(data){
    return this.http.post(this.viewChannelURL, data);
  }

  updateChannel(data){
    return this.http.post(this.updateChannelURL, data);
  }

  doctorByChannelID(data){
    return this.http.post(this.doctorByChannelURL, data);
  }

  changeDoctorAvailability(data){
    return this.http.post(this.changeDoctorAvailabilityURL, data);
  }

}
