import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-register-channeling-center',
  templateUrl: './register-channeling-center.component.html',
  styleUrls: ['./register-channeling-center.component.scss']
})
export class RegisterChannelingCenterComponent implements OnInit {

  getLabName = "";
  getContactNumber = "";
  getDescription = "";
  getCity = "";
  getLat = "";
  getLon = "";
  getLogo = "";
  getHours = "";
  getStatus = "Active";
 
  getFileType = "";
  getCityListArray;

  ImgSelectedFile: File = null;

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.ViewList();
  }

 ngOnInit(): void {

 }
 
 Validation(){

      if(this.getLabName == "" || this.getContactNumber == "" || this.getDescription == "" || this.getCity == "" || this.getLat == "" || this.getLon == ""){
        this.toastr.error('Please Fill Empty Fields', 'Error!');
      }else{
        if(this.getFileType == "image/png" || this.getFileType == "image/jpeg" || this.getFileType == "image/jpg"){
          this.Save();
        }else{
          this.toastr.error('Selected File Not Valid. please select Image.', 'Error!');
        }
      }
 }

 Save(){

   const labData = {
     name: this.getLabName,
     contact: this.getContactNumber,
     description: this.getDescription,
     openhours: this.getHours,
     city: this.getCity,
     lat: this.getLat,
     lng: this.getLon,
     logo: this.getLogo,
     addedBy: 'admin'
 };

   this.webApiService.saveChanneling(labData).subscribe((res => {

       console.log(res);
       this.toastr.success(res + "", 'Success!');
       // let response = JSON.stringify(res);
       // let e_data = JSON.parse(response).Data;
       // let e_code = JSON.parse(response).Code;

       // if(e_code == "S100"){
       //   this.toastr.success(e_data, 'Info!');
       //   this.router.navigate(['/Login']);
       // }else if(e_code == "E100"){
       //   this.toastr.error(e_data, "Error!");
       // }else{
       //   this.toastr.error('Something went wrong Please try Again', 'Error!');
       // }

   }), (err) => {
     console.log(err);

       this.toastr.error('Something went wrong Please try Again', 'Error!');
   })





 }

   _handleReaderLoaded(readerEvt) {
     var binaryString = readerEvt.target.result;
     this.getLogo = btoa(binaryString);

   }

   getBase64(event)  {
     this.ImgSelectedFile = event.target.files;
     this.getFileType = this.ImgSelectedFile[0].type;
     var reader = new FileReader();
     reader.onload = this._handleReaderLoaded.bind(this);
     reader.readAsBinaryString(this.ImgSelectedFile[0]);

 }


 ViewList(){


  this.webApiService.getCites().subscribe((res => {

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.getCityListArray = res;
        //console.log(this.getLabListArray);
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })



}



}
