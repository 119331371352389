import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-on-going-job',
  templateUrl: './on-going-job.component.html',
  styleUrls: ['./on-going-job.component.scss']
})
export class OnGoingJobComponent implements OnInit {

  getJobListArray;
  getFee = "0";

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.ViewList();
   }

  ngOnInit(): void {
  }


  ViewList(){


    this.webApiService.onGoingJob().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getJobListArray = res;
          //console.log(this.getJobListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }

}
