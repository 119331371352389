import { Component, OnInit } from '@angular/core';
import { WebapiService } from 'src/app/ClassFile/webapi.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-lab-testing',
  templateUrl: './edit-lab-testing.component.html',
  styleUrls: ['./edit-lab-testing.component.scss']
})
export class EditLabTestingComponent implements OnInit {

  getLabListArray;

  getID = "";
  getSelectLab = "";
  getTestingName = "";
  getDescription = "";
  getPrice = "";
  getAvailability = "";
  getLabPrice = "";

  constructor(private toastr: ToastrService,private webApiService: WebapiService,private route: ActivatedRoute,) {
    this.getID = this.route.snapshot.params.id;
    console.log(this.getID);
    this.getTesting();
    this.ViewList();

  }

  ViewList(){


    this.webApiService.getLabData().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getLabListArray = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

  }

  getTesting(){

    let userData = {
      id: this.getID,
    }

    this.webApiService.getTestingDetailsByID(userData).subscribe((res => {
      //console.log(res);

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        let body = JSON.parse(JSON.stringify(res));
        console.log(body);

        this.getSelectLab = body.labID;
        this.getTestingName = body.name;
        this.getDescription = body.description;
        this.getPrice = body.unitPrice;
        this.getAvailability = body.customAvailable;
        this.getLabPrice = body.labPrice

        //this.getTestingListArray = res;
        //console.log(this.getLabListArray);
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })




  }



  ngOnInit(): void {
  }



  Validation(){

    if(this.getTestingName == "" || this.getPrice == "" || this.getAvailability == ""){
      this.toastr.error("Empty Field Found", "Error");
    }else{


      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to Update this Details?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#46DCBE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.Save();
        }
      })




    }




  }

  Save(){

    const labData = {
      id: this.getID,
      name: this.getTestingName,
      description: this.getDescription,
      unitPrice: this.getPrice,
      customAvailable: this.getAvailability,
      labPrice: this.getLabPrice,
      addedBy: 'admin'
  };

    this.webApiService.updateTestingData(labData).subscribe((res) => {

        console.log(res);
        this.toastr.success("Update Success", 'Success!');

    }), (err) => {
      console.log(err);

        this.toastr.error('Something went wrong Please try Again', 'Error!');
    }

  }

}
