<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
        <div class="table-responsive ng-tns-c94-73">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover" data-page-length='15'>
          <thead> 
          <tr>
            <th><span>Service Type</span></th>
            <th><span>Job No</span></th>
            <th><span>Full Name</span></th>
            <th><span>Booking Date</span></th>
            <th><span>Tel</span></th>
            <th><span>Total</span></th>
            <th><span>Driver Finish</span></th>
            <th><span>Lab Finish</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getJobListArray">
              <td>
                <h5 *ngIf="val.serviceType == 'Home Visit' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.serviceType }}</span></h5>
                <h5 *ngIf="val.serviceType == 'Lab Visit' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-secondary ng-tns-c85-32">{{ val.serviceType }}</span></h5>
              </td>
              <td>{{ val.jobID }}</td>
              <td>{{ val.userName }}</td>
              <td>{{ val.bookingDate }}</td>
              <td>{{ val.tel }}</td>
              <td>{{ val.totalAmount }}</td>
              <td>{{ val.driverStatusUpdateTime }}</td>
              <td>{{ val.labStatusUpdateTime }}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
