import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-qrscanjobs',
  templateUrl: './qrscanjobs.component.html',
  styleUrls: ['./qrscanjobs.component.scss']
  
})
export class QrscanjobsComponent implements OnInit {

  getJobListArray;
  getFee = "0";

  total_amount = 0;
  total_discount = 0;
  total_final_amount = 0;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.ViewList();
   }

   ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
      deferRender: true,
      destroy:true,
      ordering: true,
    };
   // this.ViewList();
  }


  ViewList(){
 

    this.webApiService.getScanJob().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getJobListArray = res; 
          this.dtTrigger.next();


          for (let count = 0; count < this.getJobListArray.length; count++) {
            const element = this.getJobListArray[count];
          //  console.log(element.amount);
            this.total_amount += parseInt(element.amount);
            this.total_discount += parseInt(element.discount);
            this.total_final_amount += parseInt(element.totalPrice);
            
            
          }



          //console.log(this.getJobListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }

}
