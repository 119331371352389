import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {

  getName = "";
  getCity = "";
  getContactNumber = "";
  getPassword = "1234";
  getMonth = "12";
  getCardNo = "";
  getRefNo = "";
  

  constructor(private toastr: ToastrService,private webApiService: WebapiService) { }
 
  ngOnInit(): void {
  }



  Validation() {

    if(this.getName == "" || this.getContactNumber == "" || this.getCity == "" || this.getPassword == ""){
      this.toastr.error('Please Fill Empty Fields', 'Error!');
    }else{
      // if(this.getFileType == "image/png" || this.getFileType == "image/jpeg" || this.getFileType == "image/jpg"){
       this.Save();
      // }else{
      //   this.toastr.error('Selected File Not Valid. please select Image.', 'Error!');
      // }
    }

  } 

  clear () {
       this.getName = "",
        this.getCity= "",
        this.getContactNumber= "",
        this.getRefNo= "",
        this.getMonth= "0",
        this.getCardNo= "",
        this.getPassword= "1234"
  }

  Save(){

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var today_date = yyyy + '-' + mm + '-' + dd;
 
    const labData = {
        fullName : this.getName,
        city : this.getCity,
        contactNo : this.getContactNumber,
        registerDate: today_date, 
        refNo : this.getRefNo,
        month: this.getMonth,
        cardNo: "SH-"+this.getCardNo,  
        password: this.getPassword,  
    };
     
    this.webApiService.saveRegisterCustomer(labData).subscribe((res => {
  
        console.log(res);
        this.toastr.success("Register Success", 'Success!');
        this.clear();
  
    }), (err) => {

        console.log(err);
  
        this.toastr.error(err, 'Error!');

    })
  
  
  
  
  
  }
  

}
