import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebapiService {

  getBaseURL = environment.baseUrl;

  readonly LabRegisterURL = this.getBaseURL + "webApi/labs/create";
  readonly LabEditURL = this.getBaseURL + "webApi/labs/edit";
  readonly LabDetailsURL = this.getBaseURL + "webApi/labs/list";
  readonly RegisterEmployeeURL = this.getBaseURL + "webApi/labs/registerEmployee";
  readonly DeleteLabURL = this.getBaseURL + "webApi/labs/deleteLab";

  readonly TestingRegisterURL = this.getBaseURL + "webApi/packages/createTesting";
  readonly TestingDetailsURL = this.getBaseURL + "webApi/packages/testingList";
  readonly TestingDetailsByLabIDURL = this.getBaseURL + "webApi/packages/getTestingByLabID";
  readonly TestingDetailsByIDURL = this.getBaseURL + "webApi/packages/getTestingByID";
  readonly DeleteTestingURL = this.getBaseURL + "webApi/packages/deleteTesting";
  readonly UpdateTestingURL = this.getBaseURL + "webApi/packages/updateBloodTesting";
  readonly PackageListByIDURL = this.getBaseURL + "webApi/packages/packageById";
  readonly LabListByIDURL = this.getBaseURL + "webApi/labs/labById";
  readonly UpdatePackageURL = this.getBaseURL + "webApi/packages/updatePackage";

  readonly CreatePackageURL = this.getBaseURL + "webApi/packages/createPackage";
  readonly PackageListURL = this.getBaseURL + "webApi/packages/getPackageList";
  readonly DeletePackageListURL = this.getBaseURL + "webApi/packages/deletePackage";

  readonly RequestedJobURL = this.getBaseURL + "webApi/jobs/getRequestedJob";

  readonly RequestedJobByIdURL = this.getBaseURL + "webApi/jobs/getRequestedJobDetailsById";
  readonly RequestedCustomJobByIdURL = this.getBaseURL + "webApi/jobs/getRequestedCustomJobDetailsById";
  readonly RequestedPrescriptionJobByIdURL = this.getBaseURL + "webApi/jobs/getRequestedPrescriptionJobDetailsById";

  readonly CreateJobURL = this.getBaseURL + "webApi/jobs/createJob";
  readonly OnGoingJobURL = this.getBaseURL + "webApi/jobs/onGoingJob";
  readonly CompletedJobURL = this.getBaseURL + "webApi/jobs/completedJob";
  readonly scanJobURL = this.getBaseURL + "webApi/jobs/scanJob";

  readonly RejectRequestJobURL = this.getBaseURL + "webApi/jobs/rejectRequestJob";

  readonly DriverRegisterURL = this.getBaseURL + "webApi/drivers/create";
  readonly DriverListURL = this.getBaseURL + "webApi/drivers/list";

  readonly PendingRequestListURL = this.getBaseURL + "webApi/customers/pendingList";
  readonly ActiveCustomerListURL = this.getBaseURL + "webApi/customers/activeList";
  readonly updateMemberIDURL = this.getBaseURL + "webApi/customers/updateMemberID";


  readonly ApproveCustomerURL = this.getBaseURL + "webApi/customers/approveCustomer";
  readonly getTodayRegisterListURL = this.getBaseURL + "webApi/customers/getTodayRegisterList";
  readonly RejectCustomerURL = this.getBaseURL + "webApi/customers/rejectCustomer";
  readonly CreateAccountURL = this.getBaseURL + "webApi/customers/createAccount";

  readonly dashBoardCountURL = this.getBaseURL + "webApi/login/getDashboardCount";

  readonly addDoctorURL = this.getBaseURL + "webApi/channeling/addDoctor";
  readonly editDoctorURL = this.getBaseURL + "webApi/channeling/updateDoctor";
  readonly updateDoctorTimeURL = this.getBaseURL + "webApi/channeling/updateDoctorTime";

  readonly addChannelURL = this.getBaseURL + "webApi/channeling/addChannel";
  readonly addSpecializationURL = this.getBaseURL + "webApi/channeling/addSpecialization";

  readonly getSpecializationURL = this.getBaseURL + "webApi/channeling/getSpecialization";
  readonly getChannelURL = this.getBaseURL + "webApi/channeling/getChannelList_Web";

  readonly getCityURL = this.getBaseURL + "webApi/setting/city";

  readonly getRegisterCustomerURL = this.getBaseURL + "webApi/customers/registerCustomerFromAdmin";

  readonly passwordResetURL = this.getBaseURL + "webApi/login/resetPassword";

  constructor(private http: HttpClient) { }
 
  passwordRest(data){
    return this.http.post(this.passwordResetURL, data);
  }

  saveRegisterCustomer(data){
    return this.http.post(this.getRegisterCustomerURL, data);
  }
 
  getTodayCustomerList(){
    return this.http.get(this.getTodayRegisterListURL);
  }

  getScanJob(){
    return this.http.get(this.scanJobURL);
  }

  getCites(){
    return this.http.get(this.getCityURL);
  }

  getChannel(){
    return this.http.get(this.getChannelURL);
  }

  getSpecialization(){
    return this.http.get(this.getSpecializationURL);
  } 

  updateMemberID(data){
    return this.http.post(this.updateMemberIDURL, data);
  }

  saveChanneling(data){
    return this.http.post(this.addChannelURL, data);
  }

  saveDoctor(data){
    return this.http.post(this.addDoctorURL, data);
  }

  editDoctor(data){
    return this.http.post(this.editDoctorURL, data);
  }

  updateDoctorTime(data){
    return this.http.post(this.updateDoctorTimeURL, data);
  }

  saveSpecialization(data){
    return this.http.post(this.addSpecializationURL, data);
  }

  getDashboardCount(){
    return this.http.get(this.dashBoardCountURL);
  }

  activeCustomerList(){
    return this.http.get(this.ActiveCustomerListURL);
  }

  registerEmployee(data){
    return this.http.post(this.RegisterEmployeeURL, data);
  }

  createAccount(data){
    return this.http.post(this.CreateAccountURL, data);
  }

  onGoingJob(){
    return this.http.get(this.OnGoingJobURL);
  }

  completedJob(){
    return this.http.get(this.CompletedJobURL);
  }

  approveCustomer(data){
    return this.http.post(this.ApproveCustomerURL, data);
  }

  rejectCustomer(data){
    return this.http.post(this.RejectCustomerURL, data);
  }

  pendingRequestList(){
    return this.http.get(this.PendingRequestListURL);
  }

  rejectRequestJob(data){
    return this.http.post(this.RejectRequestJobURL, data);
  }

  getDriverList(){
    return this.http.get(this.DriverListURL);
  }

  registerDriver(data){
    return this.http.post(this.DriverRegisterURL, data);
  }

  createJob(data){
    return this.http.post(this.CreateJobURL, data);
  }

  getRequestedJobById(data){
    return this.http.post(this.RequestedJobByIdURL, data);
  }

  getRequestedCustomJobById(data){
    return this.http.post(this.RequestedCustomJobByIdURL, data);
  }

  getRequestedPrescriptionJobById(data){
    return this.http.post(this.RequestedPrescriptionJobByIdURL, data);
  }

  getRequestedJob(){
    return this.http.get(this.RequestedJobURL);
  }

  packageList(){
    return this.http.get(this.PackageListURL);
  }

  createPackage(data){
    return this.http.post(this.CreatePackageURL, data);
  }

  deletePackage(data){
    return this.http.post(this.DeletePackageListURL, data);
  }

  deleteTesting(data){
    return this.http.post(this.DeleteTestingURL, data);
  }

  getTestingDetailsByLabID(data){
    return this.http.post(this.TestingDetailsByLabIDURL, data);
  }

  getTestingDetailsByID(data){
    return this.http.post(this.TestingDetailsByIDURL, data);
  }

  saveLabData(data){
    return this.http.post(this.LabRegisterURL, data);
  }

  editLabData(data){
    return this.http.post(this.LabEditURL, data);
  }

  deleteLab(data){
    return this.http.post(this.DeleteLabURL, data);
  }

  getLabData(){
    return this.http.get(this.LabDetailsURL);
  }

  saveTestingData(data){
    return this.http.post(this.TestingRegisterURL, data);
  }

  getPackageByID(data){
    return this.http.post(this.PackageListByIDURL, data);
  }

  getLabByID(data){
    return this.http.post(this.LabListByIDURL, data);
  }

  updateTestingData(data){
    return this.http.post(this.UpdateTestingURL, data);
  }

  updatePackageData(data){
    return this.http.post(this.UpdatePackageURL, data);
  }

  getTestingData(){
    return this.http.get(this.TestingDetailsURL);
  }



}
