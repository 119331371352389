import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  registered = "0";
  pendingUser = "0";
  jobRequest = "0";
  approveCount = "0";
  channelingRequestCount = "0";
  premiumRequestCount = "0";
  Pending = "0";
  Active = "0";

  getRequestedJob;
  dashboardCount;

  constructor(private toastr: ToastrService,
    private webApiService: WebapiService,private router: Router) {

      // if(sessionStorage.getItem("isLogin") != "true"){
      //   this.router.navigate(['/Login/Login']);
      // }else{
      //   this.router.navigate(['/']);
      // }

      this.ViewRequestedJobList();
      this.getDashboardCount();


    }

  ngOnInit(): void {
  }


  ViewRequestedJobList(){


    this.webApiService.getRequestedJob().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getRequestedJob = res;
          console.log(this.getRequestedJob);
        }

    }), (err) => {
      console.log(err.error);

        this.toastr.error(err.error.Error, 'Error!');
    })


  }


  getDashboardCount(){

    this.webApiService.getDashboardCount().subscribe((res => {

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.dashboardCount = res;

        this.registered = this.dashboardCount.activeCustomer;
        this.pendingUser = this.dashboardCount.pendingCustomer;
        this.jobRequest = this.dashboardCount.requestJob;
        this.approveCount = this.dashboardCount.approveJob;
        this.channelingRequestCount = this.dashboardCount.channeling;
        this.premiumRequestCount = this.dashboardCount.premiumRequest;

        console.log(this.dashboardCount);


        //console.log(this.getLabListArray);
      }

  }), (err) => {
    console.log(err.error);

      this.toastr.error(err.error.Error, 'Error!');
  })



  }





}
