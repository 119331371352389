<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
 
  <div class="col-sm-12">
    <app-card>
      <button class="btn btn-icon btn-warning" (click)="ViewList()">Reload List</button> 
      <perfect-scrollbar [style.max-height]="'500px'">
        <div class="table-responsive ng-tns-c94-73">
        <table class="table table-hover m-b-0">
          <thead>
          <tr>
            <th><span>Actions</span></th>
            <th><span>Reg NO</span></th>
            <th><span>Full Name</span></th>
            <th><span>Tel</span></th>
            <th><span>Member Count</span></th>
            <th><span>Amount</span></th>
            <th><span>Discount Code</span></th>
            <th><span>Request DATE</span></th>
            

          </tr>
          </thead>
          <tbody> 
            <tr *ngFor="let val of getCustomerListArray">
              <td>

                <!-- <button type="button" class="btn btn-icon btn-danger ng-tns-c85-29" >
                  <i _ngcontent-khb-c116=""  class="feather icon-user-minus"></i>
                </button> -->

                <button type="button" class="btn btn-icon btn-success ng-tns-c85-29" (click)="Approve(val.requestMonth, val.customerID, val._id, val.maxMembers)">
                  <i _ngcontent-khb-c116="" class="feather icon-user-check"></i>
                </button>

                <button type="button" style="margin-left: 5px;" class="btn btn-icon btn-danger ng-tns-c85-29" (click)="delete(val._id)">
                  <i _ngcontent-khb-c116="" class="feather icon-trash"></i>
                </button>
  
              </td>
              <td>{{ val.registerNo }}</td>
              <td>{{ val.name }}</td>
              <td>{{ val.contactNo }}</td>
              <td>
                <h5 *ngIf="val.maxMembers == '8' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-info ng-tns-c85-32">{{val.maxMembers}}</span></h5>
                <h5 *ngIf="val.maxMembers == '4' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{val.maxMembers}}</span></h5>
              </td>
              <td>{{ val.amount }}</td>
              <td>{{ val.discountCode }}</td>
              <td>{{ val.requestDate }}</td>
          

            </tr>

          </tbody>
        </table>
      </div>
      </perfect-scrollbar>
    </app-card>
  </div>
</div>
