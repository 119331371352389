import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-pending-request',
  templateUrl: './pending-request.component.html',
  styleUrls: ['./pending-request.component.scss']
})
export class PendingRequestComponent implements OnInit {

  getCustomerListArray;
  getFee = "0";

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.ViewList();
   }

  ngOnInit(): void {
  }

 
  ViewList(){

    this.getCustomerListArray = [];
    this.webApiService.pendingRequestList().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getCustomerListArray = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }

  Approve(data, id,rowID, maxMember ){


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Active this Customer Account?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Active it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.approveAccount(data, id,rowID, maxMember);
      }
    })



  }
 
  approveAccount(val, id, rowID, maxMember){

      var data = {
        id : id,
        amount: val, 
        rowID: rowID,
        maxMember: maxMember,
      }
 
      this.webApiService.approveCustomer(data).subscribe((res) => {
        console.log(res);
        this.toastr.success("Successfully Activated", 'Success!');
        this.ViewList();

      }), (err) => {
        console.log(err);

          this.toastr.error('Something went wrong Please try Again', 'Error!');
      }

  }

  deleteAccount(id){

      var data = {
        id : id,
      }

      this.webApiService.rejectCustomer(data).subscribe((res) => {
        console.log(res);
        this.toastr.success("Successfully Rejected", 'Success!');
        this.ViewList();

      }), (err) => {
        console.log(err);

          this.toastr.error('Something went wrong Please try Again', 'Error!');
      }

  }

  delete(rowID ){


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Reject this Request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAccount(rowID);
      }
    })



  }






}
