<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fill Details">
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Channeling Center Name<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getLabName"
                  [(ngModel)]="getLabName">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Contact Numbers<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getContactNumber"
                  [(ngModel)]="getContactNumber">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Description<span>*</span></label>
              <div class="col-sm-9">
                <textarea
                  name="getDescription"
                  [(ngModel)]="getDescription"
                  class="form-control"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Open Hours<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getHours"
                  [(ngModel)]="getHours">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Select City<span>*</span></label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getCity"
                    [(ngModel)]="getCity">
                  <option value="">Select</option>
                  <option *ngFor="let val of getCityListArray" value="{{ val._id }}">{{ val.name }}</option>
                </select>
              </div> 
            </div>  
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Latitude<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getLat"
                  [(ngModel)]="getLat">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Longitude<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getLon"
                  [(ngModel)]="getLon">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Logo<span>*</span></label>
              <div class="col-sm-9">
                <input
                  (change)="getBase64($event)"
                  style="margin-top: 10px;"
                  type="file"
                  class="form-control"
                  accept=".png,.jpg,.jpeg"
                  >
              </div> 
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Status<span>*</span></label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getStatus"
                    [(ngModel)]="getStatus">
                  <option value="Active">Open</option>
                  <option value="Inactive">Closed</option>
                  
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Online Booking<span>*</span></label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getOnline"
                    [(ngModel)]="getOnline">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Show Time Status<span>*</span></label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getTime"
                    [(ngModel)]="getTime">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Center Open<span>*</span></label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getOpen"
                    [(ngModel)]="getOpen">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  
                </select>
              </div>
            </div>


          </form>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-success col-md-12" (click)="Validation()">Update</button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div> 
