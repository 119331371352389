import { WebapiService } from 'src/app/ClassFile/webapi.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-job-package',
  templateUrl: './create-job-package.component.html',
  styleUrls: ['./create-job-package.component.scss']
})
export class CreateJobPackageComponent implements OnInit {

  getID = "";

  requestJobID = "";

  customerRegNo = "";
  customerName = "";
  customerTel = "";
  customerID = "";

  memberID = "";
  memberName = "";
  memberTel = "";
  memberRegNo = "";
  jobType = "";
  bookingDate = "";
  jobDateTime = "";
  address = "";
  mapLocation = "";
  distance = "";
  serviceType = "";

  selectedJob = "";

  labID = "";

  lat = "";
  lng = "";

  testingList;

  subTotal = "";
  delivery = "";
  total = "";

  diverID = "";
  diverName = "";


  getRequestedJob;
  getDriverListArray;

  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private webApiService: WebapiService) {
    this.getID = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    console.log(this.getID);
    this.getDetailsByID();
    this.ViewList();

  }


  getDetailsByID(){

    var data = {
      id: this.getID
    }

    this.webApiService.getRequestedJobById(data).subscribe((res => {

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.getRequestedJob = res;
        //console.log(res);


        this.requestJobID = this.getRequestedJob[0]._id;

        this.jobType = this.getRequestedJob[0].JobType;
        this.bookingDate = this.getRequestedJob[0].bookingDate;
        this.jobDateTime = this.getRequestedJob[0].requestDate + " / " + this.getRequestedJob[0].requestTime;

        this.subTotal = this.getRequestedJob[0].subTotal;
        this.delivery = this.getRequestedJob[0].deliveryCharge;
        this.total = this.getRequestedJob[0].totalAmount;
        this.serviceType = this.getRequestedJob[0].serviceType;

        this.lat = this.getRequestedJob[0].lat;
        this.lng = this.getRequestedJob[0].lng;

        this.labID = this.getRequestedJob[0].Lab[0]._id;
        this.selectedJob = this.getRequestedJob[0].Lab[0].name;

        this.address = this.getRequestedJob[0].address;
        this.mapLocation = this.getRequestedJob[0].mapLocation;
        this.distance = this.getRequestedJob[0].distance;

        this.customerRegNo = this.getRequestedJob[0].User[0].registerNo;
        this.customerName = this.getRequestedJob[0].User[0].fullName;
        this.customerTel = this.getRequestedJob[0].User[0].contactNo;
        this.customerID = this.getRequestedJob[0].User[0]._id;

        this.memberID = this.getRequestedJob[0].Member[0]._id;
        this.memberName = this.getRequestedJob[0].Member[0].name;
        this.memberTel = this.getRequestedJob[0].Member[0].contactNo;
        this.memberRegNo = this.getRequestedJob[0].Member[0].registerNo;

        this.testingList = this.getRequestedJob[0].Package[0].testingList;


        console.log(this.getRequestedJob);

      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })


  }

  confirmationBox(){

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to approve this Job?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.createJob();
      }
    })

  }



  createJob(){

      var data = {
        requestJobID : this.requestJobID,
        customerID: this.customerID,
        userName : this.memberName,
        tel : this.customerTel + " - " + this.memberTel,
        userID : this.memberID,
        labID : this.labID,
        driverID : this.diverID,
        bookingDate : this.bookingDate,
        distance : this.distance,
        subTotal : this.subTotal,
        deliveryCharge : this.delivery,
        totalAmount : this.total,
        address : this.address,
        mapLocation : this.mapLocation,
        lat : this.lat,
        lng : this.lng,
        driverStatus : this.serviceType == 'Home Visit' ? 'Pending' : 'Completed',
        status : 'Pending',
        jobType: 'Package',
        serviceType: this.serviceType,
        testingList: this.testingList

      }


      if(this.diverID == ""){

        this.toastr.error("Please Select Driver Before Creating JOB", 'Error!');

      }else{


        this.webApiService.createJob(data).subscribe((res) => {
          this.router.navigate(['/Home']);
          console.log(res);
          this.toastr.success("Job Create Success", 'Success!');

        }), (err) => {
          console.log(err);

            this.toastr.error('Something went wrong Please try Again', 'Error!');
        }





      }









  }

  ViewList(){


    this.webApiService.getDriverList().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getDriverListArray = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }

  getDriverDetails(){




  }


  confirmationBoxReject(){

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Reject this Job?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //this.createJob();
        this.rejectJob();
      }
    })

  }

  rejectJob(){

    var data = {
      requestJobID : this.requestJobID
    }

    this.webApiService.rejectRequestJob(data).subscribe((res) => {
      this.router.navigate(['/']);
      console.log(res);
      this.toastr.success("Job Rejected", 'Success!');

    }), (err) => {
      console.log(err);

        this.toastr.error('Something went wrong Please try Again', 'Error!');
    }
  }



}
