import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-add-specialization',
  templateUrl: './add-specialization.component.html',
  styleUrls: ['./add-specialization.component.scss']
})
export class AddSpecializationComponent implements OnInit {

  getName = "";
  getDescription = "";



  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {

  }

  ngOnInit(): void {
  }

  Validation(){

    if(this.getName == "" || this.getDescription == ""){
      this.toastr.error('Please Fill Empty Fields', 'Error!');
    }else{
      // if(this.getFileType == "image/png" || this.getFileType == "image/jpeg" || this.getFileType == "image/jpg"){
        this.Save();
      // }else{
      //   this.toastr.error('Selected File Not Valid. please select Image.', 'Error!');
      // }
    }
}

Save(){

  const labData = {
    name: this.getName,
    description: this.getDescription,
    addedBy: 'admin'
  };

  this.webApiService.saveSpecialization(labData).subscribe((res => {

      console.log(res);
      this.toastr.success("Insert Success", 'Success!');
      // let response = JSON.stringify(res);
      // let e_data = JSON.parse(response).Data;
      // let e_code = JSON.parse(response).Code;

      // if(e_code == "S100"){
      //   this.toastr.success(e_data, 'Info!');
      //   this.router.navigate(['/Login']);
      // }else if(e_code == "E100"){
      //   this.toastr.error(e_data, "Error!");
      // }else{
      //   this.toastr.error('Something went wrong Please try Again', 'Error!');
      // }

  }), (err) => {
    console.log(err);

      this.toastr.error('Something went wrong Please try Again', 'Error!');
  })





}



}
