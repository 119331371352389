<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
        <button class="btn btn-icon btn-danger" (click)="reloadPage()">Refresh All</button> 
        <button class="btn btn-icon btn-warning" (click)="ViewList()" id="today">All List</button> 
        <button class="btn btn-icon btn-success" (click)="ViewListTodayList()" id="today">ToDay Reistration</button> 
  </div>
  <div class="col-sm-12">
    <app-card> 
      
        <div class="table-responsive ng-tns-c94-73">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th><span>Reg DATE</span></th> 
            <th><span>Reg NO</span></th>
            <th><span>Card No</span></th>
            <th><span>Full Name</span></th>
            <th><span>Max</span></th>
            <th><span>Tel</span></th>
            <th><span>Ref/Dis Code</span></th>
            <th><span>Status</span></th>
             
          </tr>
          </thead>
          <tbody>  
            <tr *ngFor="let val of getCustomerListArray">
              <td>{{ val.registerDate }}</td> 
              <td><h5><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-warning ng-tns-c85-32">{{ val.registerNo }}</span></h5></td>
              <td>{{ val.cardNo }} <button class="btn btn-info" (click)="updateMemberID(val._id, val.cardNo)">Edit</button></td> 
              <td>{{ val.fullName }} <br><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-info ng-tns-c85-32">{{ val.city }}</span></td>
              <td>
                <h5 *ngIf="val.maxMembers == '8' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.maxMembers }}</span></h5>
                <h5 *ngIf="val.maxMembers == '4' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-brown ng-tns-c85-32">{{ val.maxMembers }}</span></h5>
              </td>
              <td>{{ val.contactNo }}</td>
              <td>
                {{ val.refCode }}
                <h6 *ngIf="val && val.premium && val.premium.length > 0"><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-discount ng-tns-c85-32">{{ val.premium[0].discountCode }}</span></h6>
                <h6 *ngIf="val && val.premium && val.premium.length > 0"><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-price ng-tns-c85-32">{{ val.premium[0].amount }}</span></h6>
              </td>
              <td>
                <!-- {{ val.activeMonth }} -->
                <h6 *ngIf="val.activeMonth == '0' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-danger ng-tns-c85-32">NOT REDISTERED</span></h6>
                <h6 *ngIf="val.activeMonth == '' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-danger ng-tns-c85-32">NOT REDISTERED</span></h6>
                <h6 *ngIf="val.activeMonth == '12' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">REDISTERED</span></h6>
                <h6 *ngIf="val.activeMonth == '11' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">REDISTERED</span></h6>
                <h6 *ngIf="val.activeMonth == '6' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">REDISTERED</span></h6>
                <h6 *ngIf="val.activeMonth == '3' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">REDISTERED</span></h6>
              </td>  
              
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div> 
