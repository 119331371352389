import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-completed-job',
  templateUrl: './completed-job.component.html',
  styleUrls: ['./completed-job.component.scss']
})
export class CompletedJobComponent implements OnDestroy,OnInit {
  getJobListArray;
  getFee = "0";
 
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
      ordering: false
    };
    this.ViewList();
  }


  ViewList(){


    this.webApiService.completedJob().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getJobListArray = res;
          //console.log(this.getJobListArray);
          this.dtTrigger.next();
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }


}
