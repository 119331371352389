<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fill Details">
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Name<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getName"
                  [(ngModel)]="getName">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Description<span>*</span></label>
              <div class="col-sm-9">
                <textarea
                  name="getDescription"
                  [(ngModel)]="getDescription"
                  class="form-control"></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-success col-md-12" (click)="Validation()">Save</button>
            </div>
          </div>

        </div>
      </div>
    </app-card>
  </div>
</div>
