<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
      <perfect-scrollbar [style.max-height]="'500px'">
        <div class="table-responsive ng-tns-c94-73">
        <table class="table table-hover m-b-0">
          <thead>
          <tr>
            <th><span>Logo</span></th>
            <th><span>Center Name</span></th>
            <th><span>Status</span></th>
            <th><span>Doctors</span></th>
            <th><span>Action</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getLabListArray">
              <td>
                <img src="{{ getBaseURL + 'channeling/' + val.logo }}" alt="" style="width: 50px;">
              </td>
              <td>{{ val.name }} <br> {{ val.contact }} <br> {{ val.city[0].name }}</td>
              <td>Online Booking :  {{ val.isBooking }} <br> Center Open : {{ val.isOpen }} <br> Show Time Status : {{ val.isTimeShow }}</td>
              <td>
                <button type="button" class="btn btn-icon btn-info ng-tns-c85-29">
                  <i _ngcontent-khb-c116="" [routerLink]="['/DoctorByChannelID/',val._id]" class="feather icon-users"></i>
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-icon btn-dark ng-tns-c85-29">
                  <i _ngcontent-khb-c116="" [routerLink]="['/EditChanneling/',val._id]" class="feather icon-edit"></i>
                </button>
                <button type="button" class="btn btn-icon btn-danger ng-tns-c85-29" (click)="deleteLab(val._id)">
                  <i _ngcontent-khb-c116="" class="feather icon-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </perfect-scrollbar>
    </app-card>
  </div>
</div>
